
















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      item: null,
      currentTab: "",
      tabs: {
        agencies: {
          label: "Agencies",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl"]);
          },
        },
        balances: {
          label: "Dòng tiền quảng cáo",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl"]);
          },
        },
        mkt_users: {
          label: "MKT Users",
          condition() {
            return userManager.checkRole(["kt", "ksnb", "ksnbl", "mkt", "tp"]);
          },
        },
      },
    };
  },
  async created() {},
});
